@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Libre Baskerville", serif;
  color: #505050;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #505050;
  margin-bottom: 0.2rem;
}

a {
  color: #81d742;
}

.title {
  font-weight: 700;
  margin-right: 0.5rem;
}

.price {
  margin-left: 0.5rem;
}
.price::after {
  content: " €";
}

.dots {
  flex: 1 1;
  border-bottom: 2px dotted #505050;
}


@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Libre Baskerville", serif;
  color: #505050;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #505050;
  margin-bottom: 0.2rem;
}

a {
  color: #81d742;
}

.title {
  font-weight: 700;
  margin-right: 0.5rem;
}

.price {
  margin-left: 0.5rem;
}
.price::after {
  content: " €";
}

.dots {
  flex: 1;
  border-bottom: 2px dotted #505050;
}
